<template>
    <div class="main">
        <h1>Panel Admin</h1>
        <navigationTab class="menu">
            <router-link :to="{ name: 'Admin.user'}">User</router-link>
            <router-link :to="{ name: 'Admin.mood'}">Musique</router-link>
            <router-link :to="{ name: 'Admin.animation'}">Animation</router-link>
            <router-link :to="{ name: 'Admin.font_animation'}">Font Animation</router-link>
        <!--    <router-link :to="{ name: 'Admin.videos'}">Videos</router-link>--> 
            <router-link :to="{ name: 'Admin.commentary'}">Commentaire</router-link>
            <router-link :to="{ name: 'Admin.job'}">Jobs</router-link>
         <!--  <router-link :to="{ name: 'Admin.subtitles'}">Sous-titres</router-link>--> 
        </navigationTab>
    
        <div id="view">
            <router-view/>
        </div>
    </div>
</template>

<script>
//LIBS
import emitter from "tiny-emitter/instance";

//COMPONENTS
import navigationTab from "../../components/Object/navigation-tab/navigation-tab";

export default {
    name: 'Admin',
    components: {
        navigationTab
    },
    mounted() {
        emitter.emit("checkout", "hide");
    }
}
</script>

<style scoped>
.main {
    position: relative;
    width: 100%;
    height: calc(100% - 20px);
}

h1 {
    margin-top: 20px;
    margin-left: 40px;
}

.menu {
    margin-top: 20px;
    z-index: 0;
}

.menu>* {
    text-decoration: none;
}

#view {
    position: relative;
    height: calc(100% - 111px);
    width: 100%;
}
</style>